import React from 'react'

import EyeSvg from '../../../../images/eye-closed-main-page.svg'
import TheChangeSVG from '../../../Shared/svg/TheChangeSVG'
import {
  SliderWrapper,
  SiteTitle,
  Title,
  Text,
  Eye,
  EyeText,
  InstructionsText,
  LineLeft,
  LineRight,
  FixedBottomPosition
} from './styled'

const Intro = ({ showIntro, setShowPost, setShowIntro }) => {
  const showPost = () => {
    setShowIntro(false)
    setShowPost(true)
  }
  return (
    <SliderWrapper>
      <SiteTitle showIntro={showIntro}>
        <TheChangeSVG />
      </SiteTitle>

      {/* SLIDE 1 */}
      <Title showIntro={showIntro} slide1>
        Welcome
      </Title>
      <Text showIntro={showIntro} slide1>
        You’re about to discover what causes the worst damage to your planet…
      </Text>
      {/* SLIDE 1 */}

      {/* SLIDE 2 */}
      <Title showIntro={showIntro} slide2>
        Open your eyes <br /> to climate change
      </Title>
      <Text showIntro={showIntro} slide2>
        … and what are the concequences …
      </Text>
      {/* SLIDE 2 */}

      {/* SLIDE 3 */}
      <Eye src={EyeSvg} showIntro={showIntro} onClick={showPost} />
      <EyeText showIntro={showIntro} onClick={showPost}>
        Start from here
      </EyeText>

      <FixedBottomPosition>
        <InstructionsText showIntro={showIntro}>
          Click on the eye to see what’s inside.
        </InstructionsText>
      </FixedBottomPosition>

      <LineLeft showIntro={showIntro} />
      <LineRight showIntro={showIntro} />
      {/* SLIDE 3 */}
    </SliderWrapper>
  )
}

export default Intro
