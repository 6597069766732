import React, { useState, useEffect } from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { useDispatch } from 'react-redux'
import { navigate } from 'gatsby'

import { setFirstTimeUserFalse } from '../../../redux/isFirstUserState/actions'

import { Wrapper, ButtonWrapper, Button } from './styled'

import LoadingScreen from '../../Shared/LoadingScreen/LoadingScreen'
import Intro from './Intro/Intro'
import MainTemplate from '../../PostsRelated/PostTemplates/MainTemplate/MainTemplate'

const IntroPageWrapper = () => {
  const { markdownRemark: post } = useStaticQuery(graphql`
    query MyQuery {
      markdownRemark(frontmatter: { canvasPoint: { eq: "A" } }) {
        html
        frontmatter {
          title
          secondaryTitle
          canvasPoint
          secondaryText
          tertiaryText
          template
          graphResult
          graphResultArrowDirection
          graphResultMeasureUnit
          graphData {
            childrenGraphDataJson {
              x
              y
            }
          }
          backgroundImage {
            childImageSharp {
              fluid(maxWidth: 2000, quality: 70) {
                aspectRatio
                base64
                originalImg
                originalName
                presentationHeight
                presentationWidth
                sizes
                src
                srcSet
                srcSetWebp
                srcWebp
              }
            }
          }
        }
      }
    }
  `)

  const [isLoading, setIsLoading] = useState(true)
  const [showIntro, setShowIntro] = useState(false)
  const [showPost, setShowPost] = useState(false)

  useEffect(() => {
    setIsLoading(false)
    setShowIntro(true)
  }, [])

  const dispatch = useDispatch()
  const onSeeMoreClick = () => {
    dispatch(setFirstTimeUserFalse)
    navigate('/')
  }

  return (
    <>
      <Wrapper showPost={showPost}>
        {isLoading ? <LoadingScreen isLoading={isLoading} noGrain /> : null}

        <Intro
          showIntro={showIntro}
          setShowPost={setShowPost}
          setShowIntro={setShowIntro}
        />

        {showPost ? <MainTemplate post={post} isIntro /> : null}
        {showPost ? (
          <ButtonWrapper>
            <Button onClick={onSeeMoreClick}>See more eyes</Button>
          </ButtonWrapper>
        ) : null}
      </Wrapper>
    </>
  )
}

export default IntroPageWrapper
