import styled, { css, keyframes } from 'styled-components'
import { siteBreakPoints, colors } from '../../../../consts/styleConsts'

const {
  large,
  small,
  heightLarge,
  heightMedium,
  heightSmall,
  xSmall
} = siteBreakPoints
const { darkGrey2, grey3 } = colors.grey
const mobile = 600

const getTitle2EndPos = () => {
  let units = 11
  if (typeof window !== 'undefined') {
    if (window.innerWidth < 375) {
      units = 9
    }
    if (window.innerWidth > small && window.innerHeight <= heightMedium) {
      units = 10
    }
    if (window.innerWidth > small && window.innerHeight <= heightSmall) {
      units = 9
    }
  }
  return units
}

const titleSlide1Animation = keyframes`
  0% { transform: translateX(-50%) translateY(-50%) translateY(-4.4rem); opacity: 0;}
  10% { transform: translateX(-50%) translateY(-50%) translateY(-2.5rem); opacity: 1;}
  75% { transform: translateX(-50%) translateY(-50%) translateY(-2.5rem); opacity: 1;}
  95% { transform: translateX(-50%) translateY(-50%) translateY(-2.5rem); opacity: 0;}
  100% { transform: translateX(-50%) translateY(-50%) translateY(-2.5rem); opacity: 0;}
`
const textSlide1Animation = keyframes`
  0% { transform: translateX(-50%) translateY(-50%) translateY(4.4rem); opacity: 0;}
  20% { transform: translateX(-50%) translateY(-50%) translateY(2.5rem); opacity: 1;}
  77% { transform: translateX(-50%) translateY(-50%) translateY(2.5rem); opacity: 1;}
  97% { transform: translateX(-50%) translateY(-50%) translateY(2.5rem); opacity: 0;}
  100% { transform: translateX(-50%) translateY(-50%) translateY(2.5rem); opacity: 0;}
`
const titleSlide2Animation = keyframes`
  0% { transform: translateX(-50%) translateY(-50%) translateY(-4.4rem); opacity: 0;}
  10% { transform: translateX(-50%) translateY(-50%) translateY(-2.5rem); opacity: 1;}
  60% { transform: translateX(-50%) translateY(-50%) translateY(-2.5rem); opacity: 1;}
  100% { transform: translateX(-50%) translateY(-50%) translateY(-${getTitle2EndPos()}rem); opacity: 1;}
`
const textSlide2Animation = keyframes`
  0% { transform: translateX(-50%) translateY(-50%) translateY(4.4rem); opacity: 0;}
  20% { transform: translateX(-50%) translateY(-50%) translateY(2.5rem); opacity: 1;}
  65% { transform: translateX(-50%) translateY(-50%) translateY(2.5rem); opacity: 1;}
  100% { transform: translateX(-50%) translateY(-50%) translateY(2.5rem); opacity: 0;}
`

export const SliderWrapper = styled.div`
  opacity: 1;
  height: 100%;
  position: relative;
  @media screen and (min-width: ${mobile}px) and (max-height: ${heightSmall}px) {
    height: ${heightSmall}px;
  }
`
export const SiteTitle = styled.span`
  opacity: ${({ showIntro }) => (showIntro ? 1 : 0)};
  transition: opacity 1s 1.6s;
  top: 1.59rem;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  width: 7.95rem;
  height: 1.18rem;
  & svg {
    width: 100%;
  }
  @media screen and (min-width: ${small}px) and (max-height: ${heightMedium}px) {
    top: 1rem;
  }
  @media screen and (max-width: ${small}px) {
    width: 7.5rem;
  }
`
export const Title = styled.span`
  opacity: 0;
  position: absolute;
  font-family: Plex-Bold;
  font-size: 2rem;
  line-height: 2.59rem;
  letter-spacing: 5px;
  color: ${darkGrey2};
  text-transform: uppercase;
  top: 50%;
  left: 50%;
  transform: translateX(-50%);
  text-align: center;
  width: 100%;
  ${({ showIntro, slide1 }) =>
    showIntro &&
    slide1 &&
    css`
      animation: ${titleSlide1Animation} 5s forwards;
      animation-delay: 2.6s;
    `}
  ${({ showIntro, slide2 }) =>
    showIntro &&
    slide2 &&
    css`
      animation: ${titleSlide2Animation} 5s forwards;
      animation-delay: 7.8s;
    `}

    @media screen and (max-width: ${small}px) {
      font-size: 1.5rem;
      line-height: 2rem;
      width: 100%;
      padding: 0 20px;
      letter-spacing: 2.37px;
    }
`
export const Text = styled.span`
  opacity: 0;
  position: absolute;
  text-align: center;
  left: 50%;
  top: 50%;
  font-family: Plex-Light;
  font-size: 1.2rem;
  line-height: 1.8rem;
  color: ${darkGrey2};
  width: 29.4rem;
  ${({ showIntro, slide1 }) =>
    showIntro &&
    slide1 &&
    css`
      animation: ${textSlide1Animation} 5s forwards;
      animation-delay: 2.6s;
    `}
  ${({ showIntro, slide2 }) =>
    showIntro &&
    slide2 &&
    css`
      animation: ${textSlide2Animation} 5s forwards;
      animation-delay: 7.8s;
    `}

    @media screen and (max-width: ${small}px) {
      width: 100%;
      padding: 0 20px;
      font-size: 1.12rem;
      line-height: 1.43rem;
    }
    @media screen and (max-width: ${xSmall}px) {
      font-size: 1rem;
    }
`

export const Eye = styled.img`
  opacity: ${({ showIntro }) => (showIntro ? 1 : 0)};
  visibility: ${({ showIntro }) => (showIntro ? 'visible' : 'hidden')};
  transition: opacity 0.6s 12.8s, visibility 0.6s 12.8s;
  width: 23rem;
  position: absolute;
  top: calc(50% + 2.27rem);
  left: calc(50% + 0.9rem);
  transform: translate(-50%, -50%);
  cursor: pointer;
  @media screen and (max-width: ${small}px) {
    width: 15rem;
    top: calc(50% + 1.43rem);
    left: calc(50% + 0.62rem);
  }
  @media screen and (min-width: ${small}px) and (max-height: ${heightMedium}px) {
    width: 20rem;
    top: calc(50% + 1.8rem);
    left: calc(50% + 0.6rem);
  }
`
export const EyeText = styled.span`
  opacity: ${({ showIntro }) => (showIntro ? 1 : 0)};
  visibility: ${({ showIntro }) => (showIntro ? 'visible' : 'hidden')};
  transition: opacity 0.3s 13.6s, visibility 0.3s 13.6s;
  font-family: Plex-Bold;
  font-size: 1.27rem;
  line-height: 1.68rem;
  color: ${darkGrey2};
  position: absolute;
  top: 50.5%;
  left: 50%;
  transform: translate(-50%, -50%);
  cursor: pointer;
  font-size: 1rem;
  pointer-events: none;
  @media screen and (max-width: ${small}px) {
    font-size: 0.87rem;
  }
  @media screen and (min-width: ${small}px) and (max-height: ${heightMedium}px) {
    font-size: 1rem;
  }
`
export const FixedBottomPosition = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  height: auto;
  width: 100%;
  text-align: center;
`
export const InstructionsText = styled.span`
  opacity: ${({ showIntro }) => (showIntro ? 1 : 0)};
  bottom: ${({ showIntro }) => (showIntro ? 130 : 110)}px;
  transition: opacity 0.6s 13.6s, bottom 0.6s 13.6s;
  max-width: 323px;
  font-family: Plex-Light;
  color: ${darkGrey2};
  position: relative;
  text-align: center;
  font-size: 1rem;
  line-height: 1.3rem;
  @media screen and (max-width: ${small}px) {
    font-size: 1rem;
    bottom: ${({ showIntro }) => (showIntro ? 40 : 20)}px;
  }
  @media screen and (min-width: ${small}px) and (max-height: ${heightLarge}px) {
    transform: translate(-50%, 60px);
  }
  @media screen and (min-width: ${small}px) and (max-height: ${heightMedium}px) {
    font-size: 1rem;
    width: 13rem;
  }
  }
`
export const Line = styled.div`
  position: absolute;
  top: 50%;
  width: 11.86rem;
  height: 2px;
  background: ${grey3};
  transition: transform 0.4s 13.2s;
  @media screen and (max-width: ${large}px) {
    width: 5rem;
  }
  @media screen and (max-width: ${small}px) {
    width: 2.5rem;
  }
`
export const LineLeft = styled(Line)`
  left: 0;
  transform: translate(${({ showIntro }) => (showIntro ? 0 : -100)}%, -50%);
`
export const LineRight = styled(Line)`
  right: 0;
  transform: translate(${({ showIntro }) => (showIntro ? 0 : 100)}%, -50%);
`
