import styled, { css } from 'styled-components'
import { colors, siteBreakPoints } from '../../../consts/styleConsts'
import { buttonStyle } from '../../../styled/pageStyle'

const { small } = siteBreakPoints
const { darkGrey1 } = colors.grey
const width1350 = 1350
const height900 = 900

export const Wrapper = styled.div`
  width: 100vw;
  height: 100%;
  min-height: 100vh;
  background: ${darkGrey1};
  position: fixed;
  overflow: hidden;
  // -webkit-overflow-scrolling: touch;
  ${({ showPost }) =>
    showPost &&
    css`
      overflow: auto;
      overflow-x: hidden;
      position: static;
    `}
`
export const ButtonWrapper = styled.div`
  height: 61px;
  position: absolute;
  width: 100%;
  bottom: 4.09rem;
  left: 0;
  display: none;
  @media only screen and (max-width: ${width1350}px) {
    position: relative;
    bottom: 0;
    margin-bottom: 2rem;
    margin-top: 3rem;
    left: -20px;
  }
  @media only screen and (max-height: ${height900}px) {
    position: relative;
    bottom: 0;
    margin-bottom: 2rem;
    margin-top: 3rem;
    left: -20px;
  }
  @media only screen and (max-width: ${small}px) {
    display: block;
    bottom: 0;
    position: fixed;
    height: 60px;
    left: 0;
    margin-bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(35, 40, 40, 0.9);
    box-shadow: 0 -3px 10px 0 rgba(0, 0, 0, 0.2);
  }
`
export const Button = styled.span`
  ${buttonStyle};
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  @media only screen and (max-width: ${small}px) {
    position: relative;
    left: 0;
    transform: translateX(0);
    width: 100%;
    margin: 0 40px;
    padding: 9px 0;
  }
`
