import React from 'react'
import GrainBG from '../components/LayoutsRelated/GrainBG/GrainBG'
import IntroPageWrapper from '../components/PageRelated/IntroPageWrapper/IntroPageWrapper'
import SocialShareMeta from '../components/Shared/SocialShareMeta/SocialShareMeta'

const HomePage = () => {
  return (
    <>
      <SocialShareMeta />
      <GrainBG />
      <IntroPageWrapper />
    </>
  )
}

export default HomePage
